<template>
	<div style="padding: 20px;">
		<div class="dytitle">
			<span v-for="(item,index) in titlelist" :class="now==index? 'dyactive' : ''	">{{item}}</span>

		</div>
		<div class="num">
			订单编号：{{num}} <span style="margin-right: 20px; cursor: pointer;" @click="back">返回列表</span>
		</div>
		<div>
			<steps :active="active"></steps>
			<trace :active="traceactive" :trace="trace"></trace>
			<articlecont :article="article"></articlecont>
			
		</div>

	</div>
</template>

<script>
	import steps from '../components/drawbacksteps'
	import trace from '../components/trace'
	import articlecont from '../components/articlecont'
	import { checksteps, checktrace, checkarticle } from "../../../API/QKD/detail.js"
	export default {
		components: {
			steps,
			trace,
			articlecont,
		},
		data() {
			return {
				now: "",
				attr:"",
				num: '',
				active: '',
				trace: [],
				article: [],
				traceactive: "",
				titlelist: ["待缴费", "被驳回", "待确认"]
			}
		},
		mounted() {
			this.attr=this.$route.query.attr
			this.now = this.$route.query.now
			let id = this.$route.query.wid
						checksteps(id).then(res => {
							this.num = res.data.data.contributionnumber
							if(res.data.data.state == 44) {
								this.active = 2
							}  else {
								this.active = 3
							}
						})
						checktrace(id).then(res => {
			
							this.trace = res.data.data
							this.traceactive = this.trace.length - 1
						})
						checkarticle(id).then(res => {
							console.log(res)
							this.article = res.data.data
						})
		},
		methods: {
			back() {
				this.$router.push({
					path: "/Pending/todoorder",
					query: {
						"now": this.now,
						"id":0
					}
				})
			},
			
		}
	}
</script>

<style>
	.num {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
		font-size: 14px;
		line-height: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	
	.detailBottom {
		padding: 20px;
		display: flex;
		justify-content: flex-end;
	}
	
	.dytitle {
		border-bottom: 1px solid #f7f7f7;
		font-size: 14px;
		line-height: 60px;
	}
	
	.dytitle span {
		margin: 0 10px;
		padding: 0 5px;
		display: inline-block;
	}
	
	.dyactive {
		border-bottom: 1px solid red;
		margin: 0 10px;
		padding: 0 5px;
		display: inline-block;
		color: red;
	}
	
	.bottombtn {
		display: flex;
		justify-content: flex-end;
		padding: 20px;
	}
</style>